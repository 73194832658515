import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios from "axios";

// action types
export const GET_PROFILE = "getProfile";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PROFILE = "setProfile";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  token: "",
  permission: [],
  // isAuthenticated: false,
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  permission(state) {
    return state.permission;
  },
};

const actions = {
  async [LOGIN](context, payload) {
    const bodyFormData = new FormData();
    bodyFormData.append("email", payload.email);
    bodyFormData.append("password", payload.password);
    await axios
      .post(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/login`, bodyFormData)
      .then(({ data }) => {
        context.commit(SET_AUTH, data);
        return data;
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    axios
      .post(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/login`, credentials)
      .then(({ data }) => {
        context.commit(SET_AUTH, data);
        return data;
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  async [GET_PROFILE]({ commit, dispatch }) {
    if (JwtService.getToken()) {
      await axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/profile`)
        .then(({ data }) => {
          commit(SET_PROFILE, data);
          dispatch("getPermission");
        })
        .catch(({ response }) => {
          commit(PURGE_AUTH);
          commit(SET_ERROR, response.data.errors);
        });
    } else {
      commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  async getPermission({ commit }) {
    if (JwtService.getToken()) {
      await axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/role`)
        .then(({ data }) => {
          commit("setPermission", data.permissions);
        })
        .catch(({ response }) => {
          // commit(PURGE_AUTH);
          commit(SET_ERROR, response.data.errors);
        });
    } else {
      // context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, auth) {
    state.isAuthenticated = true;
    state.token = auth.token;
    state.errors = {};
    JwtService.saveToken(state.token);
  },
  [SET_PROFILE](state, user) {
    state.user = user;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  setPermission(state, permission) {
    state.permission = permission;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
