import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
// import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme

Vue.use(VueClipboard);
// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import VueSlider from "vue-slider-component";
import Vuelidate from "vuelidate";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "vue-slider-component/theme/default.css";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "swiper/css/swiper.css";
import axios from "axios";
Vue.prototype.$axios = axios;
import interceptorsSetup from "@/plugin/axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import moment from "moment";
Vue.prototype.$moment = moment;
import VueCarousel from "vue-carousel";
// API service init
// ApiService.init();

// // Remove this to disable mock API
// MockService.init();
router.beforeEach((to, from, next) => {
  next();

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueSweetalert2);
Vue.component("VueSlider", VueSlider);
Vue.use(Vuelidate);
Vue.use(VueAwesomeSwiper);
Vue.use(interceptorsSetup);
Vue.use(VueEasytable);
Vue.use(VueCarousel);
Vue.use(VueQuillEditor);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
